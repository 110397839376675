<template>
    <div class="section-thank">
        <div class="container">
            <h2 class="section-thank__title"><img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/corporat/title/thank-you-${$i18n.locale}.png`))" alt="Thank you"></h2>
            <div class="section-thank__content">
                <p class="section-thank__text">{{ $t('clickHere') }}</p>
                <div class="section-thank__row">
                    <div class="col"><a href="/product/karamucho"><img :src="webp(require(`../../../public/img/corporat/kara-mucho.png`))" alt="Kara Mucho"></a></div>
                    <div class="col"><a href="/product/koimucho"><img :src="webp(require(`../../../public/img/corporat/koi-mucho.png`))" alt="Koi Mucho"></a></div>
                </div><!--/ .section-thank__row -->
            </div><!--/ .section-thank__content -->
        </div><!--/ container -->
    </div>
</template>

<script>
    export default {
        name: "Thank"
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;

    .section-thank {
        padding-top: 30px;
        padding-bottom: 100px;
        line-height: 30px;
        font-size: 16px;
        text-align: center;
        background: {
            image: url("../../../public/img/corporat/bg/bg-thank-you-sp.jpg");
            repeat: no-repeat;
            position: right bottom;
        }
        @include media(set-min-w, $sm) {
            background: {
                image: url("../../../public/img/corporat/bg/bg-thank-you.jpg"), url("../../../public/img/corporat/bg/bg-thank-you-element.jpg");
                position: right bottom, center 80px;
            }
        }

        .container {
            max-width: 1360px;
            padding: 0 15px;
            margin: 0 auto;
        }

        &__title {
            height: 65px;
            margin-bottom: 30px;

            @include media(set-min-w, $sm) {
                height: auto;
            }

            img {
                max-height: 100%;
            }
        }

        &__text {
            margin-bottom: 15px;
            color: #929292;
            font-size: 22px;
            font-weight: 600;
            text-transform: uppercase;
        }

        &__row {
            display: flex;
            justify-content: center;

            .col {
                padding-right: 10px;
                padding-left: 10px;
            }
        }
    }
    .webp {
        .section-thank {
            background: {
                image: url("../../../public/img/corporat/bg/bg-thank-you-sp.webp");
            }
            @include media(set-min-w, $sm) {
                background: {
                    image: url("../../../public/img/corporat/bg/bg-thank-you.webp"), url("../../../public/img/corporat/bg/bg-thank-you-element.webp");
                }
            }
        }
    }
</style>